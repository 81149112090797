import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import { DropZone } from '@shopify/polaris';

import { BlogPost } from '../model';
import axios from '../../utils/axios.utils';
import siteWideStyles from '../../styles/siteWideStyles';
import BlogPostForm from '../components/BlogPostForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditBlogPostPage: React.FC = () => {
  const { blogPostId } = useParams<{ blogPostId: string }>();
  const history = useHistory();

  const [formValues, setFormValues] = useState<BlogPost>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();
  let [heroImagePreview, setHeroImagePreview] = useState<string | null>(formValues?.hero_image_url || null);
  let [thumbnailImagePreview, setThumbnailImagePreview] = useState<string | null>(formValues?.s3_image_url || null);
  let [imagesSubmitting, setImagesSubmitting] = useState<boolean>(false);

  const editBlogPost = async () => {
    const combinedFormData = new FormData();

    // Append the image file, then the rest of the formValues
    if (imageFileData) {
      imageFileData.forEach((value, key) => {
        combinedFormData.append(key, value);
      });
    }

    Object.entries(formValues).forEach(([key, value]) => {
      combinedFormData.append(`blog_post[${key}]`, value);
    });

    try {
      await axios.put(`blog_posts/${blogPostId}`, combinedFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      history.push(`/admin/blog-posts`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  const handleImageDrop = async (files: File[], category) => {
    setImagesSubmitting(true);

    const data = new FormData();
    const attachedFile = files[0];

    data.append(`file`, attachedFile, attachedFile.name);
    data.append('category', category);

    await axios.put(`/blog_posts/${blogPostId}/images`, data);

    const previewUrl = URL.createObjectURL(attachedFile);
    if (category === 'hero_image') {
      setHeroImagePreview(previewUrl);
    } else if (category === 's3_image') {
      setThumbnailImagePreview(previewUrl);
    }

    setImagesSubmitting(false);
  };

  const fetchBlogPost = async () => {
    try {
      const response = await axios.get(`blog_posts/${blogPostId}`);
      setFormValues(response?.data?.result);
      setHeroImagePreview(response?.data?.result?.hero_image_url);
      setThumbnailImagePreview(response?.data?.result?.s3_image_url);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (blogPostId) {
      fetchBlogPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogPostId]);

  const imageCollection = [
    {
      label: 'Hero Image',
      category: 'hero_image',
      preview: heroImagePreview,
      setPreview: setHeroImagePreview,
    },
    {
      label: 'Thumbnail Image',
      category: 's3_image',
      preview: thumbnailImagePreview,
      setPreview: setThumbnailImagePreview,
    },
  ];

  return (
    <Grid container style={{ padding: '2%' }} spacing={3}>
      <AdminPageHeader title="Edit Blog Post" backButton={{ label: 'Blog Posts', link: '/admin/blog-posts' }} />
      <Grid item xs={12}>
        <BlogPostForm
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setImageFileData={setImageFileData}
        />
      </Grid>
      <Grid item xs={2}>
        <Button onClick={() => editBlogPost()} style={siteWideStyles.primaryButton}>
          Save Changes
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: '2%' }}>
          {imagesSubmitting ? (
            <h3>Submitting images...</h3>
          ) : (
            <Grid container>
              {imageCollection.map((collectionData) => (
                <>
                  <Grid item xs={12} style={{ marginTop: 15 }}>
                    <h3 className="Polaris-Heading">{collectionData.label}</h3>
                  </Grid>
                  <Grid item xs={8} style={{ margin: 'auto' }}>
                    <Card title="Image">
                      <DropZone
                        type="image"
                        label=""
                        onDrop={(files) => handleImageDrop(files, collectionData.category)}
                        allowMultiple={false}
                      >
                        <DropZone.FileUpload />
                      </DropZone>
                    </Card>
                  </Grid>
                  <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {collectionData.preview && (
                      <img src={collectionData.preview} alt="" style={{ width: '50%', height: 'auto' }} />
                    )}
                  </Grid>
                </>
              ))}
            </Grid>
          )}
        </Card>
      </Grid>
      <Grid item xs={2}>
        <Button onClick={() => history.push(`/blog-posts/${blogPostId}`)} style={siteWideStyles.secondaryButton}>
          Preview
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditBlogPostPage;
