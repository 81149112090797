import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card } from '@material-ui/core';
import axios from '../../utils/axios.utils';
import { Testimonial } from '../model';
import DataTable from '../../dataTable';
import TestimonialViewer from '../../redesignedFrontend/homePage/sections/TestimonialViewer';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const TestimonialsListPage: React.FC = () => {
  const history = useHistory();

  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get('testimonials');
      setTestimonials(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const testimonialColumns = [
    {
      name: 'image',
      selector: 's3_image_url',
      sortable: false,
      grow: 0.8,
      cell: (row: any) => <img src={row.s3_image_url} alt="none" style={{ width: '50%', height: 'auto' }} />,
    },
    { name: 'Name', selector: 'name', sortable: true },
    { name: 'Job Title', selector: 'job_title', sortable: true, grow: 0.5 },
    { name: 'Status', selector: 'status', sortable: true, grow: 0.5 },
    { name: 'Quote', selector: 'quote', sortable: true, grow: 2 },
  ];

  return (
    <Grid container style={{ padding: '2%' }} spacing={2}>
      <AdminPageHeader
        title="Testimonials"
        backButton={{ link: '/admin/tools', label: 'Admin Tools' }}
        primaryButton={{ label: 'Create Testimonial', link: '/admin/testimonials/create' }}
      />
      <Grid item xs={12}>
        <Card style={{ padding: 20 }}>
          <DataTable
            data={testimonials}
            columns={testimonialColumns}
            onRowClicked={(rowData: any) => history.push(`/admin/testimonials/${rowData.id}`)}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <TestimonialViewer testimonials={testimonials} />
      </Grid>
    </Grid>
  );
};

export default TestimonialsListPage;
