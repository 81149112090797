import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Opportunity, OpportunityFormValues } from '../model';

interface OpportunitiesContextInterface {
  fetchOpportunities?: (params?: string) => Promise<void>;
  opportunities?: Opportunity[];

  fetchOpportunity?: (opportunity_id: string) => Promise<void>;
  opportunityLoading?: boolean;
  opportunitySubmitting?: boolean;
  opportunity?: Opportunity;

  categoryFilter?: string;
  setCategoryFilter?: React.Dispatch<React.SetStateAction<string>>;

  statusFilter?: string;
  setStatusFilter?: React.Dispatch<React.SetStateAction<string>>;

  totalRecords?: number;

  fetchParams?: string;
  setFetchParams?: React.Dispatch<React.SetStateAction<string>>;

  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;

  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, any[]>;
  updateOpportunity?: (opportunity_id: string, formValues: OpportunityFormValues) => Promise<void>;
  createOpportunity?: (formValues: OpportunityFormValues, onSuccess?: () => void) => Promise<void>;

  formError?: string;
}

const OpportunitiesContext = React.createContext<OpportunitiesContextInterface>({
  fetchOpportunities: undefined,
  opportunities: [],
  fetchOpportunity: undefined,
  opportunityLoading: true,
  opportunitySubmitting: false,
  formOptions: { project_options: [] },
  opportunity: {},
});

const OpportunitiesContextConsumer = OpportunitiesContext.Consumer;
const OpportunitiesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [opportunityLoading, setOpportunityLoading] = useState<boolean>(false);
  const [opportunitySubmitting, setOpportunitySubmitting] = useState<boolean>(false);
  const [opportunity, setOpportunity] = useState<Opportunity>({});
  const [categoryFilter, setCategoryFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [totalRecords, setTotalRecords] = useState<number>();
  const [formOptions, setFormOptions] = useState<Record<string, any[]>>();
  const [fetchParams, setFetchParams] = useState<string>('ransack[query]=');
  const [sortParams, setSortParams] = useState<string>('sort=');
  const [formError, setFormError] = useState<string>(undefined);

  const fetchOpportunities = async (params = '') => {
    setOpportunityLoading(true);
    const response = await axios.get<string, any>(`opportunities.json?${params}`);

    setOpportunityLoading(false);
    setOpportunities(response?.data?.result);
    setCategoryFilter(response?.data?.applied_filters?.category?.value);
    setStatusFilter(response?.data?.applied_filters?.status?.value);
    setTotalRecords(response?.data?.total_records);
  };

  const fetchOpportunity = async (opportunity_id: string) => {
    setOpportunityLoading(true);

    const response = await axios.get<string, any>(`opportunities/${opportunity_id}.json`);

    setOpportunityLoading(false);
    setOpportunity(response?.data?.result);
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`opportunities/new.json`);
    setFormOptions(response?.data);
  };

  const updateOpportunity = async (opportunity_id: string, formValues: OpportunityFormValues) => {
    setOpportunitySubmitting(true);

    await axios.put<string, any>(`opportunities/${opportunity_id}.json`, {
      opportunity: { ...formValues },
    });

    setOpportunitySubmitting(false);
    history.push(`/admin/opportunities/${opportunity_id}`);
  };

  const createOpportunity = async (formValues: OpportunityFormValues, onSuccess?: () => void) => {
    try {
      setOpportunitySubmitting(true);

      axios
        .post<string, any>(`opportunities.json`, {
          opportunity: { ...formValues },
        })
        .then((response) => {
          setOpportunitySubmitting(false);

          if (onSuccess) {
            onSuccess();
          } else {
            history.push(`/admin/opportunities/${response.data.result.id}`);
          }
        })
        .catch((error) => {
          setOpportunitySubmitting(false);
          setFormError(
            error?.response?.data?.errors?.join(', ') || 'Something went wrong, please reload the page and try again.',
          );
        });
    } catch (error: any) {
      setOpportunitySubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  return (
    <OpportunitiesContext.Provider
      value={{
        fetchOpportunities,
        opportunities,

        fetchOpportunity,
        opportunityLoading,
        opportunity,

        categoryFilter,
        setCategoryFilter,

        statusFilter,
        setStatusFilter,

        fetchParams,
        setFetchParams,

        sortParams,
        setSortParams,

        totalRecords,

        fetchFormOptions,
        formOptions,
        updateOpportunity,
        createOpportunity,
        opportunitySubmitting,

        formError,
      }}
    >
      {children}
    </OpportunitiesContext.Provider>
  );
};

export { OpportunitiesContextProvider, OpportunitiesContextConsumer, OpportunitiesContext };
