import React, { Dispatch, SetStateAction } from 'react';
import { Card, Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { Topic } from '../model';

const statusOptions = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
];

const audienceOptions = [
  { value: 'general', label: 'General' },
  { value: 'professionals', label: 'Professionals' },
];

interface TopicFormProps {
  formValues: Topic;
  setFormValues: Dispatch<SetStateAction<Topic>>;
  formErrors: string;
}

const TopicForm: React.FC<TopicFormProps> = (props: TopicFormProps) => {
  const { formValues, setFormValues, formErrors } = props;

  return (
    <Card style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormInput
            type="text"
            label="Name"
            value={formValues?.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Audience"
            value={formValues?.audience}
            choices={audienceOptions}
            onChange={(value) => setFormValues({ ...formValues, audience: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Status"
            value={formValues?.status}
            choices={statusOptions}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: 'red' }}>{formErrors}</p>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TopicForm;
