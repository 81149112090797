import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Collapse, IconButton } from '@material-ui/core';
import { KeyboardArrowDown, Close } from '@material-ui/icons';
import ResourcesAccordion from './ResourcesAccordion';
import Logo from '../../../assets/LogoWhite.png';
import useStyles from '../../redesignedFrontend/constants/redesignStyles';

// TODO: Still need to put the LogIn Icon next to the LogIn text.

const SideNavSeparatorBar: React.FC = () => {
  return (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '20px',
        marginTop: '5px',
        zIndex: 2,
      }}
    >
      <img
        src="https://cdn.theinsighters.com/KeyDetailsBorder.png"
        alt="Separator Bar"
        style={{ width: '80%', height: 'auto', margin: 10 }}
      />
    </Grid>
  );
};

interface RedesignSideNavigationProps {
  mobileNavigationActive: any;
  setMobileNavigationActive: any;
}

const RedesignSideNavigation: React.FC<RedesignSideNavigationProps> = (props) => {
  let { mobileNavigationActive, setMobileNavigationActive } = props;
  let classes = useStyles();
  let history = useHistory();

  let [resourceAccordionOpen, setResourceAccordionOpen] = useState(false);

  const handleClose = () => {
    setMobileNavigationActive(false); // Assuming this will close the side navigation
  };

  return (
    <Grid
      container
      style={{
        position: 'fixed',
        backgroundColor: '#545D78',
        zIndex: 999,
        overflow: 'auto',
        height: '100vh', // Make sure it takes the full viewport height
        maxHeight: '100vh', // Set the maximum height
      }}
    >
      <Grid item xs={12}>
        <a href="/" className="">
          <img alt="logo" src={Logo} style={{ height: '40px', margin: '10px 20px' }} />
        </a>
        <IconButton
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: '#fff',
          }}
        >
          <Close />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={() => history.push('/opportunities/general')}
      >
        <Typography variant="h6" className={classes.navHeaderPaidOpportunities}>
          Paid Opportunities
        </Typography>
        <Typography variant="h6" className={classes.navHeader}>
          For Everyone
        </Typography>
      </Grid>
      <SideNavSeparatorBar />
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={() => history.push('/opportunities/professionals')}
      >
        <Typography variant="h6" className={classes.navHeaderPaidOpportunities}>
          Paid Opportunities
        </Typography>
        <Typography variant="h6" className={classes.navHeader}>
          For Healthcare Professionals
        </Typography>
      </Grid>
      <SideNavSeparatorBar />
      <Grid
        item
        xs={12}
        style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center' }}
        onClick={() => history.push('/about')}
      >
        <Typography variant="h6" className={classes.navHeader}>
          About
        </Typography>
      </Grid>
      <SideNavSeparatorBar />
      <Grid
        item
        xs={12}
        style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center' }}
        onClick={() => setResourceAccordionOpen(!resourceAccordionOpen)}
      >
        <Typography variant="h6" className={classes.navHeader}>
          <span style={{ color: '#F3C66B' }}>Resources </span>
          <KeyboardArrowDown
            style={{
              transform: resourceAccordionOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease-in-out',
              fontWeight: 'bold',
            }}
          />
        </Typography>
      </Grid>
      <Collapse in={resourceAccordionOpen} timeout="auto" unmountOnExit>
        <Grid item xs={12} style={{ marginBottom: 10, paddingLeft: '12%', paddingRight: '12%' }}>
          <ResourcesAccordion />
        </Grid>
      </Collapse>
      <Grid
        item
        xs={12}
        style={{ cursor: 'pointer', marginTop: 25, display: 'flex', justifyContent: 'center' }}
        onClick={() => history.push('/login')}
      >
        <Typography variant="h6" className={classes.navHeader}>
          Log In
        </Typography>
      </Grid>
      {/* An empty grid to push the background to the bottom of the view screen. */}
      <Grid item xs={12} style={{ minHeight: '60vh' }} />
    </Grid>
  );
};

export default RedesignSideNavigation;
