import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loading, Page, Toast } from '@shopify/polaris';
import { Grid } from '@mui/material';
import OpportunitiesBanner from '../../../assets/opportunities/opportunities-banner.png';
import OpportunityCard from '../components/OpportunityCard';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import { InsighterOpportunitiesContext } from '../contexts/InsighterOpportunitiesContext';
import BasicFooter from '../../navigation/components/BasicFooter';
import { InsightersContext } from '../../insighters/contexts/InsightersContext';

interface OpportunitiesListProps {
  isLoading?: boolean;
}

const InsighterOpportunitiesList: React.FunctionComponent<OpportunitiesListProps> = (props) => {
  const { isLoading = false } = props;
  const { impersonatedUserId } = useParams<Record<string, string>>();
  const history = useHistory();
  const { insighterOpportunities, fetchInsighterOpportunities } = React.useContext(InsighterOpportunitiesContext);
  const { insighter, fetchInsighter } = React.useContext(InsightersContext);
  const [insighterOpportunitiesFetched, setInsighterOpportunitiesFetched] = React.useState(false);
  const [insighterFetched, setInsighterFetched] = React.useState(false);
  const [toastActive, setToastActive] = React.useState(false);

  const loadingMarkup = isLoading ? <Loading /> : null;

  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);

  React.useEffect(() => {
    if (!insighterOpportunitiesFetched) {
      fetchInsighterOpportunities(impersonatedUserId);
      setInsighterOpportunitiesFetched(true);
    }
  }, [insighterOpportunitiesFetched, fetchInsighterOpportunities, impersonatedUserId]);

  React.useEffect(() => {
    if (impersonatedUserId) {
      if (!insighterFetched) {
        fetchInsighter(impersonatedUserId);
        setInsighterFetched(true);
      }
    }
  }, [insighterFetched, fetchInsighter, impersonatedUserId]);

  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const actualPageMarkup = (
    <div>
      <Page fullWidth>
        <div style={{ minHeight: '70vh' }}>
          <img alt="Upcoming" src={OpportunitiesBanner} width="100%" />
          <Grid container columnSpacing={{ xs: 0, sm: 6 }} rowSpacing={6} justifyContent="center" alignItems="center">
            {insighterOpportunities.map((opportunity, index) => (
              <Grid item lg={6} xs={10} key={index}>
                <OpportunityCard opportunity={opportunity} />
              </Grid>
            ))}
          </Grid>
          <br />
        </div>
      </Page>
      <BasicFooter />
    </div>
  );

  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
      {impersonatedUserId && (
        <Toast
          onDismiss={() => null}
          content={`Viewing page as ${insighter?.name}`}
          action={{
            content: 'Back',
            onAction: () => history.push(`/admin/insighters/${impersonatedUserId}/view`),
          }}
        />
      )}
    </>
  );
};

export default InsighterOpportunitiesList;
