import React from 'react';
import { Modal, TextContainer } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import ButtonWrapper from '../../form/components/ButtonWrapper';
import { Opportunity } from '../model';

interface OpportunityCardProps {
  opportunity: Opportunity;
}

const OpportunityCard: React.FC<OpportunityCardProps> = (props) => {
  const { opportunity } = props;
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);

  const formatOpportunityStatus = (status: string): string => {
    if (status === 'prescreen') {
      return 'Pre-Apply Now';
    }

    if (status === 'booked') {
      return 'Fully Booked';
    }

    return opportunity?.prompt;
  };

  return (
    <div className="Polaris-Card">
      <Modal open={openDetailsModal} onClose={() => setOpenDetailsModal(false)} title={opportunity.name}>
        <Modal.Section>
          <TextContainer>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="uploaded-content" dangerouslySetInnerHTML={{ __html: opportunity.learn_more }} />
          </TextContainer>
        </Modal.Section>
        <hr style={{ height: 1, backgroundColor: '#ddd', border: 'none' }} />
        <Grid
          container
          spacing={1}
          style={{ paddingRight: 15, paddingBottom: 10, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Grid item>
            <ButtonWrapper onClick={() => setOpenDetailsModal(false)} label="Close" />
          </Grid>
          <Grid item>
            <ButtonWrapper theme="primary" link={opportunity.screener_url} label={opportunity?.button_label} />
          </Grid>
        </Grid>
      </Modal>
      <div className="Polaris-MediaCard">
        <div className="Polaris-MediaCard__MediaContainer" style={{ display: 'flex', alignItems: 'center' }}>
          {(opportunity.image_url || opportunity.s3_image_url) && (
            <img
              alt=""
              width="100%"
              height="auto"
              src={opportunity.s3_image_url || opportunity.image_url}
              style={{ objectFit: 'cover', objectPosition: 'center center' }}
            />
          )}
        </div>
        <div className="Polaris-MediaCard__InfoContainer" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="Polaris-Card__Section">
            <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingTight">
              <div className="Polaris-Stack__Item">
                <div className="Polaris-MediaCard__Heading">
                  <h2 className="Polaris-Heading" style={{ fontSize: 20 }}>
                    {opportunity.name}
                  </h2>
                </div>
              </div>
              <div className="Polaris-Stack__Item">
                {/* eslint-disable-next-line react/no-danger */}
                <div className="uploaded-content" dangerouslySetInnerHTML={{ __html: opportunity.description }} />
              </div>
              <div className="Polaris-Stack__Item" style={{ marginTop: 20 }}>
                <span className="Polaris-Navigation__Indicator">
                  <span
                    className={
                      opportunity.status === 'booked'
                        ? 'Polaris-Indicator Polaris-Indicator--pulseIndicator warning'
                        : 'Polaris-Indicator Polaris-Indicator--pulseIndicator'
                    }
                  />
                </span>
                <p style={{ display: 'inline-block', marginLeft: 10, position: 'relative', top: -4, fontWeight: 600 }}>
                  {formatOpportunityStatus(opportunity.status)}
                </p>
              </div>
              <div className="Polaris-Stack__Item">
                <div className="Polaris-MediaCard__ActionContainer">
                  <div className="Polaris-ButtonGroup">
                    <div className="Polaris-ButtonGroup__Item">
                      <div className="Polaris-MediaCard__PrimaryAction">
                        <ButtonWrapper
                          theme="primary"
                          link={opportunity.screener_url}
                          label={opportunity?.button_label}
                        />
                        {opportunity?.learn_more && (
                          <button
                            className="Polaris-Button"
                            type="button"
                            style={{ marginLeft: 7 }}
                            onClick={() => setOpenDetailsModal(true)}
                          >
                            <span className="Polaris-Button__Content">
                              <span className="Polaris-Button__Text">Learn More</span>
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityCard;
