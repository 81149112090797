import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import OpportunitiesListHero from './sections/OpportunitiesListHero';
import OpportunitiesFilterBarDesktop from './sections/OpportunitiesFilterBarDesktop';
import OpportunitiesFilterBarMobile from './sections/OpportunitiesFilterBarMobile';
import OpportunitiesGallery from './sections/OpportunitiesGallery';
import GetPaidForResearchBanner from './sections/GetPaidForResearchBanner';
import RedesignFooter from '../common/RedesignFooter';

import { Opportunity } from '../../opportunities/model';

import axios from '../../utils/axios.utils';

const useStyles = makeStyles((theme) => ({
  sectionGrid: {
    margin: 30,

    [theme.breakpoints.down(960)]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const GeneralOpportunitiesListPage: React.FC = () => {
  let classes = useStyles();

  let [generalOpportunities, setGeneralOpportunities] = useState<Opportunity[]>([]);
  let [topicOptions, setTopicOptions] = useState<any[]>([]);

  const fetchGeneralOpportunities = async (topic_id?: string, query?: string) => {
    try {
      const response = await axios.get('/insighter_opportunities', {
        params: { topic_id, 'ransack[query]': query, 'ransack[audience_eq]': 'general' },
      });
      setGeneralOpportunities(response.data.result);
      setTopicOptions(response.data.topic_options);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGeneralOpportunities();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunitiesListHero heroImageUrl="https://cdn.theinsighters.com/OpportunitiesHero.png" />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <GetPaidForResearchBanner />
      </Grid>
      {window.innerWidth < 960 ? (
        <OpportunitiesFilterBarMobile fetchOpportunities={fetchGeneralOpportunities} topicOptions={topicOptions} />
      ) : (
        <OpportunitiesFilterBarDesktop fetchOpportunities={fetchGeneralOpportunities} topicOptions={topicOptions} />
      )}
      <Grid item xs={12} className={classes.sectionGrid} style={{ display: 'flex', justifyContent: 'center' }}>
        <OpportunitiesGallery redesignOpportunities={generalOpportunities} />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default GeneralOpportunitiesListPage;
