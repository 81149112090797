import React, { useState, useEffect, useContext } from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import ReactQuill from 'react-quill';
import axios from '../../utils/axios.utils';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import ConditionAutocompleteInput from '../../form/components/ConditionAutocompleteInput';
import UngroupedAutocompleteInput from '../../form/components/UngroupedAutocompleteInput';
import { OpportunitiesContext } from '../contexts/OpportunitiesContext';
import { Opportunity, OpportunityFormValues } from '../model';

interface OpportunityFormProps {
  opportunity?: Opportunity;
  afterCreate?: () => void;
}

const OpportunityForm: React.FC<OpportunityFormProps> = (props) => {
  let { opportunity, afterCreate } = props;
  let { updateOpportunity, createOpportunity, opportunitySubmitting, formOptions, formError } =
    useContext(OpportunitiesContext);

  let [phaseOptions, setPhaseOptions] = useState<any[]>([]);
  let [formValues, setFormValues] = useState<OpportunityFormValues>({});
  let [filteredTopicOptions, setFilteredTopicOptions] = useState<any[]>([]);

  useEffect(() => {
    setFormValues({
      name: opportunity.name,
      project_id: opportunity.project_id,
      phase_id: opportunity.phase_id,
      start_date: opportunity.start_date_form,
      end_date: opportunity.end_date_form,
      category: opportunity.category,
      condition_names: opportunity.condition_names || [],
      insighter_role_names: opportunity.insighter_role_names || [],
      specialty_names: opportunity.specialty_names || [],
      status: opportunity.status,
      description: opportunity.description,
      learn_more: opportunity.learn_more,
      screener_url: opportunity.screener_url,
      follow_up_success_message: opportunity.follow_up_success_message,
      follow_up_failure_message: opportunity.follow_up_failure_message,
      follow_up_category: opportunity.follow_up_category,
      follow_up_link: opportunity.follow_up_link,
      invitation_amount: opportunity.invitation_amount,
      update_status: opportunity.update_status,
      thumbnail_description: opportunity.thumbnail_description,
    });

    if (opportunity?.project_id) {
      axios.get<string, any>(`opportunities/new?project_id=${opportunity?.project_id}`).then((response) => {
        setPhaseOptions(response?.data?.phase_options);
      });
    }
  }, [opportunity]);

  const handleUpdate = () => {
    updateOpportunity(opportunity.id, formValues);
  };

  const handleProjectChange = async (value: string) => {
    await axios.get<string, any>(`opportunities/new?project_id=${value}`).then((response) => {
      setPhaseOptions(response?.data?.phase_options);
      setFormValues({ ...formValues, project_id: value, phase_id: '' });
    });
  };

  const handleCreate = () => {
    if (afterCreate) {
      createOpportunity(formValues, afterCreate);
    } else {
      createOpportunity(formValues);
    }
  };

  useEffect(() => {
    setFilteredTopicOptions(formOptions?.topic_options?.filter((topic) => topic.audience === formValues.audience));
  }, [formOptions, formValues]);

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Opportunity Information</h2>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Title"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Opportunity Category"
            choices={[
              { label: 'Survey', value: 'Survey' },
              { label: 'Interview (Virtual)', value: 'Interview (Virtual)' },
              { label: 'Interview (In-Person)', value: 'Interview (In-Person)' },
              { label: 'In-House Quiz', value: 'In-House Quiz' },
              { label: 'In-House Survey', value: 'In-House Survey' },
              { label: 'In-House Research', value: 'In-House Research' },
            ]}
            onChange={(value) => setFormValues({ ...formValues, category: value })}
            value={formValues.category}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Audience"
            choices={[
              { label: 'General', value: 'general' },
              { label: 'Healthcare Professionals', value: 'professionals' },
            ]}
            onChange={(value) => setFormValues({ ...formValues, audience: value })}
            value={formValues.audience}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Project"
            choices={formOptions?.project_options}
            onChange={(value) => handleProjectChange(value)}
            value={formValues.project_id}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Phase"
            choices={phaseOptions}
            onChange={(value) => setFormValues({ ...formValues, phase_id: value })}
            value={formValues.phase_id}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Start Date"
            type="date"
            value={formValues.start_date}
            onChange={(value) => setFormValues({ ...formValues, start_date: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="End Date"
            type="date"
            value={formValues.end_date}
            onChange={(value) => setFormValues({ ...formValues, end_date: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Invitation Amount"
            type="money"
            onChange={(value) => {
              const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
              setFormValues({ ...formValues, invitation_amount: formattedValue });
            }}
            value={formValues.invitation_amount}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Active', value: 'active' },
              { label: 'Pre-Screen', value: 'prescreen' },
              { label: 'Fully Booked', value: 'booked' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
            value={formValues.status}
          />
        </Grid>
        <Grid item xs={6} style={{ marginBottom: 10 }}>
          <SelectInput
            label="Automatically Update Status"
            choices={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={formValues.update_status}
            onChange={(value) => setFormValues({ ...formValues, update_status: value })}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          {filteredTopicOptions?.length > 0 ? (
            <UngroupedAutocompleteInput
              label="Topics"
              choices={filteredTopicOptions}
              onChange={(value) => {
                setFormValues({ ...formValues, topic_names: value });
              }}
              value={formValues.topic_names}
            />
          ) : (
            <p>Topic Options will appear here once you select an Audience</p>
          )}
        </Grid>
        <Grid item xs={12}>
          <ConditionAutocompleteInput
            label="Conditions"
            onChange={(value) => {
              setFormValues({ ...formValues, condition_names: value });
            }}
            value={formValues.condition_names}
          />
          <p style={{ fontSize: 'small', fontStyle: 'italic' }}>Leave blank to allow for any condition</p>
        </Grid>
        <Grid item xs={12}>
          <UngroupedAutocompleteInput
            label="Roles"
            choices={formOptions?.insighter_role_options}
            onChange={(value) => {
              setFormValues({ ...formValues, insighter_role_names: value });
            }}
            value={formValues.insighter_role_names}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <UngroupedAutocompleteInput
            label="Specialties"
            choices={formOptions?.specialty_options}
            onChange={(value) => {
              setFormValues({ ...formValues, specialty_names: value });
            }}
            value={formValues.specialty_names}
          />
        </Grid>
        <Grid item xs={12}>
          <p style={{ marginBottom: 5, marginTop: -30 }} className="Polaris-Label__Text">
            Opportunity Overview
          </p>
          <ReactQuill
            theme="snow"
            value={formValues.description}
            onChange={(value) => setFormValues({ ...formValues, description: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <p style={{ marginBottom: 5 }} className="Polaris-Label__Text">
            Application Process
          </p>
          <ReactQuill
            id="application-process"
            theme="snow"
            value={formValues.learn_more}
            onChange={(value) => setFormValues({ ...formValues, learn_more: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Opportunity Links and Additional Details</h2>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Thumbnail Description"
            value={formValues.thumbnail_description}
            onChange={(value) => setFormValues({ ...formValues, thumbnail_description: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Apply Now URL"
            value={formValues.screener_url}
            onChange={(value) => setFormValues({ ...formValues, screener_url: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Pre-Screen Success Message (optional)"
            value={formValues.follow_up_success_message}
            onChange={(value) => setFormValues({ ...formValues, follow_up_success_message: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Pre-Screen Fail Message (optional)"
            value={formValues.follow_up_failure_message}
            onChange={(value) => setFormValues({ ...formValues, follow_up_failure_message: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Follow-up Category (optional)"
            choices={[
              { label: 'Calendly', value: 'calendly' },
              { label: 'Qualtrics', value: 'qualtrics' },
              { label: 'Custom', value: 'custom' },
            ]}
            onChange={(value) => setFormValues({ ...formValues, follow_up_category: value })}
            value={formValues.follow_up_category}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Follow-up Link (optional)"
            value={formValues.follow_up_link}
            onChange={(value) => setFormValues({ ...formValues, follow_up_link: value })}
          />
        </Grid>
      </Grid>
      <>{formError && <p style={{ marginBottom: '8px', color: 'red' }}>{formError}</p>}</>
      <>
        {opportunity.id ? (
          <Button primary onClick={handleUpdate} loading={opportunitySubmitting}>
            Update Opportunity
          </Button>
        ) : (
          <Button primary onClick={handleCreate} loading={opportunitySubmitting}>
            Create Opportunity
          </Button>
        )}
      </>
    </FormLayout>
  );
};

export default OpportunityForm;
