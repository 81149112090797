import React from 'react';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import siteWideStyles from '../../styles/siteWideStyles';

interface AdminPageHeaderProps {
  title: string;
  backButton?: {
    label: string;
    link: string;
  };
  primaryButton?: {
    label: string;
    link: string;
  };
  badge?: {
    label: string;
    color: string;
  };
}

const AdminPageHeader: React.FC<AdminPageHeaderProps> = (props) => {
  let history = useHistory();

  let { title, backButton, primaryButton, badge } = props;

  return (
    <Grid container spacing={2} style={{ padding: 10 }}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {backButton && (
            <Button onClick={() => history.push(backButton?.link)} style={siteWideStyles.secondaryButton}>
              {backButton?.label}
            </Button>
          )}
        </div>
        <div>
          {primaryButton && (
            <Button onClick={() => history.push(primaryButton?.link)} style={siteWideStyles.primaryButton}>
              {primaryButton.label}
            </Button>
          )}
        </div>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ fontSize: 24, fontWeight: 400 }}>{title}</Typography>
        {badge && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 20,
              backgroundColor: badge?.color,
              borderRadius: 15,
              padding: 3,
              minWidth: 75,
            }}
          >
            {badge?.label}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminPageHeader;
