import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import HoverButton from '../../common/HoverButton';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import MaryFoxBio from '../assets/MaryFoxBio.png';
import AlyssaBio from '../assets/AlyssaBio.png';
import AmyBio from '../assets/AmyBio.png';
import TenaBio from '../assets/TenaBio.png';
import TinaBio from '../assets/TinaBio.png';
import BioCard from '../components/BioCard';
import BioModal from '../components/BioModal';

import { BioModalInfo } from '../../model';

const useCustomStyles = makeStyles((theme) => ({
  bioStyles: {
    width: '100%',
    height: 'auto',
    objectFit: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.2) 5px 5px 15px',
    borderRadius: 8,

    [theme.breakpoints.down(960)]: {
      objectFit: 'contain',
      boxShadow: 'none',
    },
  },
  bioContainer: {
    maxHeight: 300,

    [theme.breakpoints.down(960)]: {
      marginBottom: 20,
    },
  },
}));

let biodatas = [
  {
    name: 'Mary Fox',
    img: 'https://cdn.theinsighters.com/BioPicMary.png',
    enhancedImg: 'https://cdn.theinsighters.com/BioPicMaryEnhanced.png',
    title: 'Vice President, Strategic Growth & Business Development',
    blurb:
      'Mary is passionate about connecting real people & real stories to research. She believes in the power of language to effect change, & is obsessed with bridging gaps between patients, healthcare providers, & the companies that need to hear from them. Mary holds an M.A. in Applied Linguistics & is a Forbes Business Development Council Member.',
    linkedInLink: 'https://www.linkedin.com/in/mary-fox-vp/',
  },
  {
    name: 'Tina Aswani-Omprakash',
    img: 'https://cdn.theinsighters.com/BioPicTina.png',
    enhancedImg: 'https://cdn.theinsighters.com/BioPicTina.png',
    title: 'Senior Director of Strategic Partnerships',
    blurb:
      "Tina's strength lies in health promotion and literacy to empower patient communities with patient-centric language and educational content. She is passionate about health research being patient-powered. Tina is all about connecting patients, multidisciplinary healthcare providers, and industry to create sustainable patient-forward change in healthcare spaces. Tina holds a Master's in Public Health (MPH). She has a prominent blog called Own Your Crohn's and sits on the Board of Directors of a patient and clinician-led 501c3 charitable organization called South Asian IBD Alliance (SAIA).",
    linkedInLink: '',
  },
  {
    name: 'Tena Novak',
    img: 'https://cdn.theinsighters.com/BioPicTena.png',
    enhancedImg: 'https://cdn.theinsighters.com/BioPicTena.png',
    title: 'Senior Recruitment & Engagement Coordinator',
    blurb:
      "Tena's strong organizational skills & attention to detail ensure that each & every Insighter has a smooth opportunity experience—from initial application to final payment. She is passionate about growing The Insighters® community by identifying new groups & building new connections with both patients and healthcare providers.",
    linkedInLink: '',
  },
  {
    name: 'Amy Christianson',
    img: 'https://cdn.theinsighters.com/BioPicAmy.png',
    enhancedImg: 'https://cdn.theinsighters.com/BioPicAmy.png',
    title: 'Recruitment & Engagement Coordinator',
    blurb:
      'Passionate about people and progress, Amy seeks a genuine connection between The Insighters® mission and every patient, healthcare provider, and healthcare company who crosses her path. She works alongside her team to build a strong and knowledgeable Insighter community, and loves to use her organization and management skills to be an asset in any way she can! Amy holds a M.S. in Management and Leadership and over 6 years of recruitment and engagement experience.',
    linkedInLink: 'https://www.linkedin.com/in/amy-christianson-research-recruitment-manager-2b5a83106/',
  },
  {
    name: 'Alyssa Medwynter',
    img: 'https://cdn.theinsighters.com/BioPicAlyssa.png',
    enhancedImg: 'https://cdn.theinsighters.com/BioPicAlyssa.png',
    title: 'Digital Marketing Specialist',
    blurb:
      'Spearheading the Digital Marketing division, Alyssa is well-versed in social media & passionate about health advocacy off & online. She is proud of & believes in her work at The Insighters® as she uses her creative talents to build an online community for all Insighters to be heard.',
    linkedInLink: '',
  },
];

const InsightersTeamBios: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  let [bioModalOpen, setBioModalOpen] = useState(false);
  let [bioModalInfo, setBioModalInfo] = useState<BioModalInfo | null>(null);

  return (
    <Grid container justifyContent="center" style={{ maxWidth: 1200, margin: 'auto' }}>
      <BioModal bioModalOpen={bioModalOpen} setBioModalOpen={setBioModalOpen} bioModalInfo={bioModalInfo} />
      <MediumOrnament text="Meet Our Team" centerComponent centerText />
      <Typography className={classes.h2} style={{ textAlign: 'center' }}>
        The Real People Behind The Insighters
      </Typography>
      <br />
      <Grid container style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }} spacing={2}>
        {biodatas.map((biodata, index) => (
          <Grid item md={6} xs={12} key={`biodata-${index}`}>
            <BioCard biodata={biodata} setBioModalOpen={setBioModalOpen} setBioModalInfo={setBioModalInfo} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default InsightersTeamBios;
