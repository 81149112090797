import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Badge, Card, DropZone, Layout, Loading, Modal, TextContainer } from '@shopify/polaris';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import axios from '../../utils/axios.utils';
import { OpportunitiesContext } from '../contexts/OpportunitiesContext';
import { UserContext } from '../../auth/contexts/UserContext';
import OpportunityCard from '../components/OpportunityCard';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';
import ArchiveOpportunityConfirmModal from '../components/ArchiveOpportunityConfirmModal';
import CopyOpportunityConfirmModal from '../components/CopyOpportunityConfirmModal';

import siteWideStyles from '../../styles/siteWideStyles';

const OpportunityDetail: React.FunctionComponent = () => {
  const history = useHistory();
  const { opportunityId } = useParams<Record<string, string>>();

  const { opportunity, fetchOpportunity, updateOpportunity } = React.useContext(OpportunitiesContext);
  const { currentUserHasRole } = React.useContext(UserContext);

  let [archiveOpportunityConfirmModalOpen, setArchiveOpportunityConfirmModalOpen] = useState<boolean>(false);
  let [copyOpportunityConfirmModalOpen, setCopyOpportunityConfirmModalOpen] = useState<boolean>(false);
  const [opportunityFetched, setOpportunityFetched] = React.useState<boolean>(false);

  const loadingMarkup = !opportunityFetched ? <Loading /> : null;

  React.useEffect(() => {
    if (!opportunityFetched) {
      fetchOpportunity(opportunityId);
      setOpportunityFetched(true);
    }
  }, [opportunityFetched, fetchOpportunity, opportunityId]);

  const handleUpload = (files: File[], category) => {
    const data = new FormData();
    const attachedFile = files[0];

    data.append(`file`, attachedFile, attachedFile.name);
    data.append('category', category);

    axios.post(`/opportunity_images?id=${opportunity.id}`, data).then(() => {
      history.push(`/admin/opportunities/${opportunityId}`);
    });
  };

  const statusBadge = () => {
    if (['active', 'prescreen'].includes(opportunity?.status)) {
      return (
        <Badge status="success">{opportunity?.status?.charAt(0)?.toUpperCase() + opportunity?.status?.slice(1)}</Badge>
      );
    }
    if (opportunity?.status === 'archived') {
      return <Badge status="critical">Archived</Badge>;
    }
    return (
      <Badge status="critical">{opportunity?.status?.charAt(0)?.toUpperCase() + opportunity?.status?.slice(1)}</Badge>
    );
  };

  // ---- Page markup ----
  const pageMarkup = (
    <Grid container>
      <ArchiveOpportunityConfirmModal
        opportunityId={opportunity?.id}
        archiveOpportunityConfirmModalOpen={archiveOpportunityConfirmModalOpen}
        setArchiveOpportunityConfirmModalOpen={setArchiveOpportunityConfirmModalOpen}
      />
      <CopyOpportunityConfirmModal
        opportunityId={opportunity?.id}
        copyOpportunityConfirmModalOpen={copyOpportunityConfirmModalOpen}
        setCopyOpportunityConfirmModalOpen={setCopyOpportunityConfirmModalOpen}
      />
      <AdminPageHeader
        title={opportunity.name}
        primaryButton={{ label: 'Edit', link: `/admin/opportunities/${opportunity?.id}/edit` }}
        backButton={{ label: opportunity?.project_number, link: `/admin/projects/${opportunity?.project_id}` }}
      />
      <Layout>
        <Layout.Section>
          <Card sectioned title="Opportunity Details">
            <Grid container>
              <Grid item xs={4}>
                <Card.Section title="Name">
                  <p>{opportunity.name}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Audience">
                  <p>{opportunity.audience}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Project Name">
                  <p>{opportunity.project_name}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Phase Name">
                  <p>{opportunity.phase_name}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Category">
                  <p>{opportunity.category}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Topic">
                  <p>{opportunity.topic}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Conditions">
                  <p>{opportunity.diagnosis}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Roles">
                  <p>{opportunity.insighter_roles}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Specialties">
                  <p>{opportunity.specialties}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Start Date">
                  <p>{opportunity.start_date}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="End Date">
                  <p>{opportunity.end_date}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Status">
                  <p
                    style={{
                      fontWeight: 700,
                      textTransform: 'uppercase',
                      fontSize: 16,
                    }}
                  >
                    {opportunity.status}
                  </p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Invitation Amount">
                  <p>${opportunity.invitation_amount}</p>
                </Card.Section>
              </Grid>
              <Grid item xs={4}>
                <Card.Section title="Automatically Update Status">
                  <p>{opportunity?.update_status ? 'Yes' : 'No'}</p>
                </Card.Section>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Card.Section title="Quick Description">
                  {/* eslint-disable-next-line */}
                  <div dangerouslySetInnerHTML={{ __html: opportunity.description }} />
                </Card.Section>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Card.Section title="Learn More">
                  {/* eslint-disable-next-line */}
                  <div dangerouslySetInnerHTML={{ __html: opportunity.learn_more }} />
                </Card.Section>
              </Grid>
            </Grid>
          </Card>
          <Card sectioned title="Screening and Follow-up Information">
            <Grid container>
              <Grid item xs={12}>
                <Card.Section title="Pre-Screener URL">
                  <p>{opportunity.screener_url}</p>
                </Card.Section>
              </Grid>
              {opportunity.follow_up_failure_message && (
                <Grid item xs={12}>
                  <Card.Section title="Screening Failure Message">
                    <p>{opportunity.follow_up_failure_message}</p>
                  </Card.Section>
                </Grid>
              )}
              {opportunity.follow_up_success_message && (
                <Grid item xs={12}>
                  <Card.Section title="Screening Success Message">
                    <p>{opportunity.follow_up_success_message}</p>
                  </Card.Section>
                </Grid>
              )}
              {opportunity.follow_up_category && (
                <Grid item xs={4}>
                  <Card.Section title="Follow-up Category">
                    <p>{opportunity.follow_up_category}</p>
                  </Card.Section>
                </Grid>
              )}
              {opportunity.follow_up_category && (
                <Grid item xs={8}>
                  <Card.Section title={`${opportunity.follow_up_category} Link`}>
                    <p>{opportunity.follow_up_link}</p>
                  </Card.Section>
                </Grid>
              )}
            </Grid>
          </Card>
          <Card title="Opportunity Images" sectioned>
            <Grid container>
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <h3 className="Polaris-Heading">Hero Image</h3>
              </Grid>
              <Grid item xs={6}>
                <DropZone
                  type="image"
                  label=""
                  onDrop={(files) => handleUpload(files, 'hero_image')}
                  allowMultiple={false}
                >
                  <DropZone.FileUpload />
                </DropZone>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {opportunity.hero_image_url ? (
                  <img src={opportunity.hero_image_url} alt="Preview" style={{ width: 'auto', height: '200px' }} />
                ) : (
                  <p>Upload an image to see the preview here.</p>
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <h3 className="Polaris-Heading">Application Process Image</h3>
              </Grid>
              <Grid item xs={6}>
                <DropZone
                  type="image"
                  label=""
                  onDrop={(files) => handleUpload(files, 'content_image')}
                  allowMultiple={false}
                >
                  <DropZone.FileUpload />
                </DropZone>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {opportunity.content_image_url ? (
                  <img src={opportunity.content_image_url} alt="Preview" style={{ width: 'auto', height: '200px' }} />
                ) : (
                  <p>Upload an image to see the preview here.</p>
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <h3 className="Polaris-Heading">Thumbnail Image</h3>
              </Grid>
              <Grid item xs={6}>
                <DropZone
                  type="image"
                  label=""
                  onDrop={(files) => handleUpload(files, 'thumbnail_image')}
                  allowMultiple={false}
                >
                  <DropZone.FileUpload />
                </DropZone>
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {opportunity.thumbnail_image_url ? (
                  <img src={opportunity.thumbnail_image_url} alt="Preview" style={{ width: 'auto', height: '200px' }} />
                ) : (
                  <p>Upload an image to see the preview here.</p>
                )}
              </Grid>
            </Grid>
          </Card>
          <br />
          <Grid container>
            <Grid item xs={12} spacing={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    style={siteWideStyles.primaryButton}
                    onClick={() => history.push(`/admin/opportunities/${opportunity?.id}/edit`)}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={siteWideStyles.secondaryButton}
                    onClick={() => history.push(`/opportunities/${opportunity?.id}`)}
                  >
                    Preview
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    style={siteWideStyles.secondaryButton}
                    onClick={() => history.push(`/admin/opportunities/${opportunity?.id}/key-details`)}
                  >
                    Key Details
                  </Button>
                </Grid>
                <Grid item>
                  <Button style={siteWideStyles.primaryButton} onClick={() => setCopyOpportunityConfirmModalOpen(true)}>
                    Copy
                  </Button>
                </Grid>
                {currentUserHasRole('superadmin') && (
                  <Grid item>
                    {opportunity?.status === 'archived' ? (
                      <Button
                        onClick={() => {
                          updateOpportunity(opportunity?.id, { status: 'inactive' });
                        }}
                        style={siteWideStyles.destructiveButton}
                      >
                        Unarchive
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setArchiveOpportunityConfirmModalOpen(true);
                        }}
                        style={siteWideStyles.destructiveButton}
                      >
                        Archive
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Layout.Section>
      </Layout>
    </Grid>
  );

  // ---- Loading ----
  // const loadingPageMarkup = <DetailPageSkeleton />;
  // const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default OpportunityDetail;
