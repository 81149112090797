import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { Icon } from '../model';
import axios from '../../utils/axios.utils';
import useStyles from '../../redesignedFrontend/constants/redesignStyles';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const IconsListPage: React.FC = () => {
  let history = useHistory();
  let classes = useStyles();

  let [icons, setIcons] = useState<Icon[]>([]);

  let fetchIcons = async () => {
    try {
      let response = await axios.get('icons');
      setIcons(response?.data?.result);
    } catch {
      console.log('Error fetching icons');
    }
  };

  useEffect(() => {
    fetchIcons();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container style={{ padding: '2%' }}>
      <AdminPageHeader
        title="Icons"
        backButton={{ label: 'Admin Tools', link: '/admin/tools' }}
        primaryButton={{ label: 'Add Icon', link: '/admin/icons/create' }}
      />
      <Grid item xs={12}>
        <Card style={{ padding: 10 }}>
          <Grid container>
            {icons.map((icon) => (
              <Grid
                item
                xs={1}
                key={icon.id}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: 3,
                  alignItems: 'center',
                  cursor: 'pointer',
                  margin: 5,
                }}
                onClick={() => history.push(`/admin/icons/${icon.id}/edit`)}
              >
                <img src={icon.s3_image_url} alt={icon.name} style={{ width: '50px', height: 'auto' }} />
                <Typography className={classes.smallText} style={{ marginTop: 5 }}>
                  {icon.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default IconsListPage;
