import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Edit from '@material-ui/icons/Edit';
import axios from '../../utils/axios.utils';
import { BlogPost } from '../model';
import DataTable from '../../dataTable';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const BlogPostsListPage: React.FC = () => {
  const history = useHistory();

  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);

  const fetchBlogPosts = async () => {
    try {
      const response = await axios.get('blog_posts');
      setBlogPosts(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const blogPostColumns = [
    {
      name: 'Pic',
      selector: 'pic',
      sortable: true,
      grow: 0.5,
      cell: (row: any) => (
        <img src={row.s3_image_url} alt={row.title} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
      ),
    },
    { name: 'Title', selector: 'title', sortable: true },
    { name: 'Author', selector: 'author_name', sortable: true, cell: (row: any) => row.blog_author?.name },
    { name: 'Thumbnail Description', selector: 'thumbnail_description', grow: 2 },
    { name: 'Status', selector: 'status', sortable: true, grow: 0.5 },
    {
      name: 'Featured',
      selector: 'featured',
      sortable: true,
      grow: 0.5,
      cell: (row: any) => (row.featured ? 'yes' : 'no'),
    },
    {
      name: 'Edit',
      selector: 'edit',
      grow: 0.5,
      cell: (row: any) => (
        <Edit
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/admin/blog-posts/${row.id}/edit`);
          }}
        />
      ),
    },
  ];

  return (
    <Grid container spacing={3} style={{ padding: '2%' }}>
      <AdminPageHeader
        title="Blog Posts"
        primaryButton={{ label: 'Add Blog Post', link: '/admin/blog-posts/create' }}
        backButton={{ label: 'Admin Tools', link: '/admin/tools' }}
      />
      <Grid item xs={12}>
        <Card>
          <DataTable
            data={blogPosts}
            columns={blogPostColumns}
            onRowClicked={(rowData: any) => history.push(`/blog-posts/${rowData.id}`)}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default BlogPostsListPage;
