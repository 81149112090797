import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import axios from '../../utils/axios.utils';
import { CaseStudy } from '../model';
import CaseStudyTitleSection from '../components/CaseStudyTitleSection';
import CaseStudyOverviewSection from '../components/CaseStudyOverviewSection';
import CaseStudyBodySection from '../components/CaseStudyBodySection';
import CaseStudyConclusionSection from '../components/CaseStudyConclusionSection';
import DeleteCaseStudyConfirmModal from '../components/DeleteCaseStudyConfirmModal';
import ContentManagementButtons from '../../shared/components/ContentManagementButtons';
import Footer from '../../redesignedFrontend/common/RedesignFooter';

const CaseStudyDetailPage: React.FC = () => {
  let { caseStudyId } = useParams<{ caseStudyId: string }>();

  let [caseStudy, setCaseStudy] = useState<CaseStudy>();
  let [deleteCaseStudyConfirmModalOpen, setDeleteCaseStudyConfirmModalOpen] = useState<boolean>(false);

  const fetchCaseStudy = async () => {
    try {
      const response = await axios.get(`case_studies/${caseStudyId}`);
      setCaseStudy(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCaseStudy();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container style={{ padding: '2%' }}>
      <DeleteCaseStudyConfirmModal
        caseStudyId={caseStudyId}
        deleteCaseStudyConfirmModalOpen={deleteCaseStudyConfirmModalOpen}
        setDeleteCaseStudyConfirmModalOpen={setDeleteCaseStudyConfirmModalOpen}
      />
      <Grid item xs={12} style={{ padding: 10 }}>
        <ContentManagementButtons
          recordId={caseStudyId}
          recordLink="case-studies"
          recordLabelPlural="Case Studies"
          recordLabelSingular="Case Study"
          deleteModalOpen={setDeleteCaseStudyConfirmModalOpen}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 30 }}>
        <CaseStudyTitleSection caseStudy={caseStudy} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 30 }}>
        <CaseStudyOverviewSection caseStudy={caseStudy} />
      </Grid>
      <Grid item xs={12}>
        <CaseStudyBodySection caseStudy={caseStudy} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 30 }}>
        <CaseStudyConclusionSection caseStudy={caseStudy} />
      </Grid>
      <Grid item xs={12} style={{ padding: 10 }}>
        <ContentManagementButtons
          recordId={caseStudyId}
          recordLink="case-studies"
          recordLabelPlural="Case Studies"
          recordLabelSingular="Case Study"
          deleteModalOpen={setDeleteCaseStudyConfirmModalOpen}
        />
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default CaseStudyDetailPage;
