import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import OpportunityScroller from '../../common/OpportunityScroller';
import { Opportunity } from '../../../opportunities/model';
import axios from '../../../utils/axios.utils';

const useCustomStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    maxWidth: 1000,
    margin: 'auto',
    justifyContent: 'flex-start', // Default style

    [theme.breakpoints.down(960)]: {
      justifyContent: 'center', // Center content on smaller screens
    },
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',

    [theme.breakpoints.down(960)]: {
      justifyContent: 'center', // Center content on smaller screens
      textAlign: 'center',
    },
  },
}));

const ExplorePaidOpportunitiesSection: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const [generalOpportunities, setGeneralOpportunities] = useState<Opportunity[]>([]);
  const [professionalOpportunities, setProfessionalOpportunities] = useState<Opportunity[]>([]);

  useEffect(() => {
    axios.get('insighter_opportunities.json?redesign=true').then((response) => {
      const opportunities = response?.data?.result;

      setGeneralOpportunities(opportunities.filter((opportunity) => opportunity.audience === 'General'));
      setProfessionalOpportunities(opportunities.filter((opportunity) => opportunity.audience === 'Professionals'));
    });
  }, []);

  return (
    <Grid container spacing={0} className={customClasses.container}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '24px' }}>
        <MediumOrnament text="Unlocking Potential" centerText centerComponent={window.innerWidth <= 960} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={customClasses.textWrapper}
        style={{ marginTop: -20, padding: 20, marginBottom: '24px' }}
      >
        <Typography className={classes.h2} style={{ fontWeight: 600 }}>
          Explore Paid <span style={{ color: '#488C7A' }}>Opportunities</span>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} className={customClasses.textWrapper} style={{ marginBottom: '24px' }}>
        <Typography
          className={classes.smallText}
          style={{ lineHeight: '1.5em', marginTop: -20, width: '80%', fontWeight: 500 }}
        >
          We connect you to paid opportunities where you can share your story, your experience, and your insights
        </Typography>
      </Grid>
      <Grid item xs={9} md={6} style={{ display: 'flex', justifyContent: 'center', marginBottom: 40, padding: 12 }}>
        <OpportunityScroller
          hoverButtonText="For Everyone"
          hoverButtonLink="/opportunities/general"
          opportunities={generalOpportunities}
        />
      </Grid>
      <Grid item xs={9} md={6} style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 40, padding: 12 }}>
        <OpportunityScroller
          hoverButtonText="For Healthcare Professionals"
          hoverButtonLink="/opportunities/professionals"
          opportunities={professionalOpportunities}
        />
      </Grid>
    </Grid>
  );
};

export default ExplorePaidOpportunitiesSection;
