import React from 'react';
import { useHistory } from 'react-router';
import { Grid, IconButton, Typography, Hidden, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/HighlightOff';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from '../../utils/axios.utils';
import Logo from '../../../assets/logo.png';
import LogoWhite from '../../../assets/LogoWhite.png';
import LoginSplat from '../../../assets/redesignAssets/staticImages/LogInSplat.png';
import Users from '../../../assets/redesignAssets/staticImages/Users.png';
import MegaMenuBackground from '../assets/MegaMenuBackground.png';
import MegaMenuResourceBackground from '../assets/MegaMenuResourceBackground.png';
import { HomepageResource } from '../../redesignedFrontend/resourcesPage/model';
import MediumOrnament from '../../redesignedFrontend/common/MediumOrnament';
import ReadMoreArrow from '../../shared/components/ReadMoreArrow';

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      height: '40px',
      margin: '10px 20px',
      lineHeight: '28px',
      display: 'none',
      [theme.breakpoints.up(360)]: {
        display: 'inline-block',
      },
    },
    megaMenu: {
      position: 'absolute',
      width: '100%',
      backgroundColor: '#3A4254',
      display: 'none',
      zIndex: 1000,
      top: 0,
      left: 0,
      minHeight: '80vh',
      cursor: 'default',
    },
    megaMenuLogo: {
      height: '40px',
      margin: '10px 20px',
      lineHeight: '28px',
    },
    latestInsights: {},
    latestInsightsTitle: {
      color: '#fff', // Default color
      fontFamily: 'Crimson Text',
      fontSize: '28px',
      textDecoration: 'none',
      fontWeight: 700,
      textAlign: 'left',
    },
    megaMenuResourceContainer: {
      backgroundColor: 'white',
      borderRadius: 15,
      textAlign: 'left',
      height: 150,
      marginTop: 10,
    },
    megaMenuResourceTitle: {
      fontFamily: 'Crimson Text',
      fontSize: '20px',
      lineHeight: '22px',
      textDecoration: 'none',
      fontWeight: 700,
      textAlign: 'left',
    },
    megaMenuLink: {
      color: '#fff', // Default color
      fontFamily: 'Crimson Text',
      fontSize: '36px',
      textDecoration: 'none',
      fontWeight: 700,
      maxWidth: '250px',
      display: 'block', // Makes the whole area clickable
      '&:hover': {
        color: '#F3C66B', // Color on hover
      },
    },
  }),
);

const styles = {
  navMenuButton: {
    cursor: 'pointer',
    lineHeight: '60px',
    width: 'auto',
    background: 'none',
    border: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-end',
    height: '100%',
  },
  paidOpportunityText: {
    textDecoration: 'none',
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Rethink Sans',
    fontSize: 10,
    letterSpacing: '0.5px',
    width: 'auto',
    padding: 0,
    textTransform: 'uppercase' as const,
  },
  navMenuText: {
    textDecoration: 'none',
    color: '#000',
    fontWeight: 700,
    fontFamily: 'Crimson Text',
    fontSize: 20,
    letterSpacing: '-0.2px',
    width: 'auto',
    padding: 0,
    marginTop: '-4px',
  },
  buttonLink: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  },
};

interface RedesignTopNavigationProps {
  mobileNavigationActive: any;
  setMobileNavigationActive: any;
}

const RedesignTopNavigation: React.FC<RedesignTopNavigationProps> = (props) => {
  const { mobileNavigationActive, setMobileNavigationActive } = props;
  const classes = useStyles();
  const history = useHistory();

  const [megaMenuVisible, setMegaMenuVisible] = React.useState(false);
  const [featuredResources, setFeaturedResources] = React.useState<HomepageResource[]>([]);

  const fetchInitialHomepageResources = async (category?: string, query?: string) => {
    try {
      const response = await axios.get('/homepage_resources', { params: { category, query } });
      let allResources = response.data?.result;
      setFeaturedResources(allResources.filter((resource: HomepageResource) => resource.featured));
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchInitialHomepageResources();
  }, []);

  return (
    <Grid
      container
      className="Polaris-TopBar"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        boxShadow: 'none',
        background: 'inherit',
      }}
    >
      <Grid item>
        <button
          type="button"
          className="Polaris-TopBar__NavigationIcon"
          aria-label="Toggle menu"
          onClick={() => setMobileNavigationActive(true)}
          style={{ marginTop: 7 }}
        >
          <span className="Polaris-Icon">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
              <path d="M19 11H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0-7H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14H1a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z" />
            </svg>
          </span>
        </button>
      </Grid>
      <Grid item md={3}>
        <button type="button" style={{ ...styles.buttonLink, float: 'left' }} onClick={() => history.push('/')}>
          <img alt="logo" src={Logo} className={classes.logo} style={{ float: 'left' }} />
        </button>
      </Grid>
      <Grid item md={9}>
        <Grid container justifyContent="space-between">
          <Hidden smDown>
            <Grid item style={{ maxWidth: '600px', width: '80%' }}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/opportunities/general')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={styles.navMenuButton}
                  >
                    <Typography style={styles.paidOpportunityText}>Paid Opportunities</Typography>
                    <Typography style={styles.navMenuText}>For Everyone</Typography>
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/opportunities/professionals')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={styles.navMenuButton}
                  >
                    <Typography style={styles.paidOpportunityText}>Paid Opportunities</Typography>
                    <Typography style={styles.navMenuText}>For Healthcare Professionals</Typography>
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/about')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={styles.navMenuButton}
                  >
                    <Typography style={styles.navMenuText}>About</Typography>
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onMouseEnter={() => setMegaMenuVisible(true)}
                    onMouseLeave={() => setMegaMenuVisible(false)}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={styles.navMenuButton}
                  >
                    <Typography style={styles.navMenuText}>
                      Resources
                      <KeyboardArrowDownIcon
                        style={{
                          transform: 'rotate(0deg)',
                          transition: 'transform 0.3s ease-in-out',
                          fontWeight: 'bold',
                          position: 'relative',
                          top: 4,
                          left: 2,
                        }}
                      />
                    </Typography>
                    <div className={classes.megaMenu} style={{ display: megaMenuVisible ? 'block' : 'none' }}>
                      <Grid container style={{ maxWidth: 1400, margin: 'auto', padding: 30 }}>
                        <Grid item xs={8}>
                          <Grid
                            container
                            spacing={2}
                            style={{ backgroundColor: '#545D78', height: '100%', borderRadius: 20 }}
                          >
                            <Grid item xs={12}>
                              <div style={{ height: 50 }}>
                                <img
                                  alt="logo"
                                  src={LogoWhite}
                                  className={classes.megaMenuLogo}
                                  style={{ float: 'left' }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} className={classes.latestInsights} style={{ padding: 50 }}>
                              <h3 className={classes.latestInsightsTitle}>Latest Insights</h3>
                              {featuredResources?.slice(0, 3).map((resource) => (
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  key={resource.id}
                                  className={classes.megaMenuResourceContainer}
                                >
                                  <Grid item>
                                    <img
                                      src={resource.s3_image_url}
                                      alt={resource.title}
                                      style={{ width: '150px', height: '150px' }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      paddingLeft: 20,
                                      maxWidth: 'calc(100% - 180px)',
                                      display: 'flex',
                                      alignItems: 'center',
                                      height: 150,
                                    }}
                                  >
                                    <div>
                                      <MediumOrnament
                                        text={resource.formatted_category_name}
                                        centerText
                                        centerComponent={false}
                                      />
                                      <h3 className={classes.megaMenuResourceTitle}>{resource.title}</h3>
                                      <ReadMoreArrow message="Read more" link={resource?.link} />
                                    </div>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{ paddingTop: 100, paddingLeft: 20, textAlign: 'left', position: 'relative' }}
                        >
                          <IconButton
                            onClick={() => setMegaMenuVisible(false)}
                            style={{
                              position: 'absolute',
                              left: 0,
                              top: 0,
                              color: 'black',
                              margin: '10px',
                              zIndex: 1200,
                              backgroundColor: 'transparent',
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                marginLeft: '35px',
                                userSelect: 'none',
                                fontSize: '24px',
                                fontFamily: 'Crimson Text',
                                color: '#fff',
                                fontWeight: 700,
                              }}
                            >
                              Close
                            </Typography>
                            <CloseIcon style={{ fontSize: '24px', color: '#fff', marginLeft: 5 }} />
                          </IconButton>
                          <ul style={{ listStyleType: 'none' }}>
                            <li style={{ borderBottom: '1px solid #767676', maxWidth: '250px' }}>
                              <a href="resources/blog-post" className={classes.megaMenuLink}>
                                Articles
                              </a>
                            </li>
                            <li style={{ borderBottom: '1px solid #767676', maxWidth: '250px' }}>
                              <a href="resources/case-study" className={classes.megaMenuLink}>
                                Case Studies
                              </a>
                            </li>
                            <li style={{ borderBottom: '1px solid #767676', maxWidth: '250px' }}>
                              <a href="/resources/event" className={classes.megaMenuLink}>
                                Events
                              </a>
                            </li>
                            <li>
                              <a href="/frequently-asked-questions" className={classes.megaMenuLink}>
                                Got a Question?
                              </a>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </div>
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container onClick={() => history.push('/login')} style={{ cursor: 'pointer' }}>
              <Grid
                item
                style={{
                  backgroundImage: `url(${LoginSplat})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '50px',
                  width: '50px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  padding: 8,
                  marginTop: 4,
                  marginRight: 10,
                }}
              >
                <img src={Users} alt="Users" style={{ width: '80%', height: 'auto' }} />
              </Grid>
              <Grid item>
                <p style={styles.navMenuButton}>Log In</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </AppBar> */}
    </Grid>
  );
};

export default RedesignTopNavigation;
