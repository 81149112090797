import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import OpportunitiesListHero from './sections/OpportunitiesListHero';
import GetPaidForResearchBanner from './sections/GetPaidForResearchBanner';
import OpportunitiesFilterBarMobile from './sections/OpportunitiesFilterBarMobile';
import OpportunitiesFilterBarDesktop from './sections/OpportunitiesFilterBarDesktop';
import OpportunitiesGallery from './sections/OpportunitiesGallery';
import RedesignFooter from '../common/RedesignFooter';

import { Opportunity } from '../../opportunities/model';

import axios from '../../utils/axios.utils';

const useStyles = makeStyles((theme) => ({
  sectionGrid: {
    margin: 30,

    [theme.breakpoints.down(960)]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const HealthcareProfessionalsOpportunitiesListPage: React.FC = () => {
  let classes = useStyles();

  let [healthcareProfessionalsOpportunities, setHealthcareProfessionalsOpportunities] = useState<Opportunity[]>([]);
  let [topicOptions, setTopicOptions] = useState<any[]>();

  const fetchHealthcareProfessionalsOpportunities = async (topic_id?: string, query?: string) => {
    try {
      const response = await axios.get('/insighter_opportunities', {
        params: { topic_id, 'ransack[query]': query, 'ransack[audience_eq]': 'professionals' },
      });
      setHealthcareProfessionalsOpportunities(response.data.result);
      setTopicOptions(response.data.topic_options);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchHealthcareProfessionalsOpportunities();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunitiesListHero heroImageUrl="https://cdn.theinsighters.com/DoctorAndPatient.png" />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <GetPaidForResearchBanner />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        {window.innerWidth < 960 ? (
          <OpportunitiesFilterBarMobile
            fetchOpportunities={fetchHealthcareProfessionalsOpportunities}
            topicOptions={topicOptions}
          />
        ) : (
          <OpportunitiesFilterBarDesktop
            fetchOpportunities={fetchHealthcareProfessionalsOpportunities}
            topicOptions={topicOptions}
          />
        )}
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunitiesGallery redesignOpportunities={healthcareProfessionalsOpportunities} />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default HealthcareProfessionalsOpportunitiesListPage;
